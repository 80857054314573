import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import {
  AppCollaborationFeatures,
  useRedirectByAccessCollaborator,
  useRedirectIfNotConfirmed
} from '@features';
import { useGetCurrentApp } from '@features/stories/hooks';
import { Tabs, TabType } from '@components';
import { AppIntegrationsTab } from '../../components';
import './AppIntegrationsRoute.scss';

const b = block('AppIntegrationsRoute');

export const AppIntegrationsRoute: React.FC = () => {
  const { t } = useTranslation();

  const currentApp = useGetCurrentApp();
  useRedirectIfNotConfirmed();

  useRedirectByAccessCollaborator({
    feature: AppCollaborationFeatures.CHANGE_APP_SETTINGS,
    app: currentApp ?? undefined
  });

  const tabsItems: TabType[] = [
    {
      id: 'summary',
      title: t('dashboard.integrations.title'),
      component: AppIntegrationsTab
    }
    // {
    //   id: 'billing',
    //   title: t('user.billing'),
    //   link: `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`
    // }
  ];

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs tabs={tabsItems} />
      </div>
    </div>
  );
};
