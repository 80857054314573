import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  CropProps,
  informSlice,
  KB_IN_MB,
  MAX_GROUP_AVATAR_SIZE_KB,
  MessageTypes
} from '@features';
import { MAX_GROUP_AVATAR_SIZE_MB } from '@features/consts';
import { useGetGroupLastPosition } from '@features/groups/hooks';
import { FormGroup, FormLabel } from '@components';
import { FreshModalTitle, FreshButton, FreshInput, FreshDropArea } from '@components/_fresh';
import { fetchCreateGroup } from '../../groupsSlice';
import { useAppDispatch } from '../../../../store';
import './CreateGroupContainer.scss';

const b = block('CreateGroupContainer');

interface CreateGroupContainerProps {
  currentAppId?: string;
  className?: string;
  onClose?(groupId?: string): void;
  currentImage: CropProps;
  clearCurrentImage(): void;
  onImageLoaded(params: CropProps): void;
  defaultName?: string;
}

interface State {
  name: string;
  image: string | null;
}

const initialState: State = {
  name: '',
  image: null
};

const NAME_MAX_LENGTH = 20;

export const CreateGroupContainer: React.FC<CreateGroupContainerProps> = (props) => {
  const {
    currentAppId,
    currentImage,
    className,
    defaultName,
    onClose,
    onImageLoaded,
    clearCurrentImage
  } = props;
  const imageFiles = React.useRef<File[] | null>(null);
  const dispatch = useAppDispatch();
  const { appId } = useParams<{ appId: string }>();

  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [groupForm, setGroupForm] = useState(initialState);

  const lastGroupPosition = useGetGroupLastPosition();

  useEffect(() => {
    if (defaultName) {
      setGroupForm({ ...groupForm, name: defaultName });
    }
  }, []);

  useEffect(() => {
    if (groupForm.name.trim().length !== 0 && groupForm.name.length <= NAME_MAX_LENGTH) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [groupForm, currentImage]);

  const handleChangeName = ({ value }: { value: string }) => {
    setGroupForm({ ...groupForm, name: value });
  };

  const handleCreateGroup = () => {
    if (currentImage) {
      setButtonDisabled(true);
      dispatch(
        fetchCreateGroup({
          appId: currentAppId || appId,
          name: groupForm.name,
          image: currentImage.blob,
          startTime: DateTime.now().set({ hour: 0, minute: 0 }).toMillis().toString(),
          position: lastGroupPosition + 1
        })
      )
        .unwrap()
        .then((groupId) => {
          setGroupForm(initialState);
          setButtonDisabled(false);
          clearCurrentImage();
          onClose && onClose(groupId || '');
        });
    }
  };

  const handleFileChange = (files: File[]) => {
    imageFiles.current = files;

    if (files[0] && files[0].size / KB_IN_MB > MAX_GROUP_AVATAR_SIZE_KB) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.WARN,
          text: `${t('errors.fileSize')} ${MAX_GROUP_AVATAR_SIZE_MB}MB.`
        })
      );
    } else {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = () => {
        onImageLoaded({ dataUrl: reader.result as string, blob: file });
      };
    }
  };

  return (
    <div className={`${b().toString()} ${className || ''}`}>
      <FreshModalTitle>{t('dashboard.modals.addNewGroup.title')}</FreshModalTitle>
      <FreshDropArea
        className={`${b('dropArea')}`}
        currentImage={currentImage.dataUrl}
        handleFileChange={handleFileChange}
      />
      <FormGroup>
        <div className={b('labelContainer')}>
          <FormLabel className={b('lableTitle').toString()} isBlack>
            {t('dashboard.modals.addNewGroup.groupName')}
          </FormLabel>
          <p className={b('labelInfo', { red: groupForm.name.length >= NAME_MAX_LENGTH })}>
            {t('dashboard.modals.max')} {NAME_MAX_LENGTH}
          </p>
        </div>
        <FreshInput
          fieldName="name"
          invalid={groupForm.name.length >= NAME_MAX_LENGTH}
          placeholder={t('dashboard.modals.addNewGroup.groupNamePlaceholder')}
          type="text"
          value={groupForm.name}
          onChange={handleChangeName}
        />
      </FormGroup>
      <FreshButton
        className={b('btn')}
        disabled={buttonDisabled}
        text={t('dashboard.modals.addNewGroup.addBtn')}
        onClick={handleCreateGroup}
      />
    </div>
  );
};
