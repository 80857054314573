import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import {
  GroupChooseItems,
  GroupsType,
  StoriesGroup,
  Story,
  StoryChoosePreview,
  StoryLayer,
  Templates
} from '@features';
import { useHistory } from 'react-router-dom';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { FreshButton, FreshModal, FreshModalTitle } from '@components/_fresh';
import { getActiveStoriesFlattenedArrFromLayersArr } from '@utils';
import { useCreateStoriesFromTemplate } from '@hooks';
import './AddTemplatesModal.scss';

const b = block('AddTemplatesModal');

interface AddTemplatesModalProps {
  appId: string;
  stories: StoryLayer[];
  category: Templates.Category;
  groups: StoriesGroup[];
  isOpen: boolean;
  isProhibitedOutsideClick?: boolean;
  onClose(): void;
  onOpenAddModal?(category: Templates.Category): void;
}

export const AddTemplatesModal: React.FC<AddTemplatesModalProps> = (props) => {
  const {
    isOpen,
    appId,
    category,
    groups,
    stories,
    isProhibitedOutsideClick,
    onOpenAddModal,
    onClose
  } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const templateSettings = useSelector(
    (store: RootState) => store.templateStories.selectedGroupSettings
  );

  const activeStories = getActiveStoriesFlattenedArrFromLayersArr(stories);
  const [chosenStories, setChosenStories] = useState<string[]>(
    activeStories.map((story) => story.layerData.layersGroupId)
  );
  const [chosenGroup, setChosenGroup] = useState<string>(groups.length ? groups[0].id : '');
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    setChosenStories(activeStories.map((story) => story.layerData.layersGroupId));
  }, [activeStories.length]);

  useEffect(() => {
    if (groups.length) {
      setChosenGroup(groups[0].id);
    } else {
      setChosenGroup('');
    }
  }, [groups.length]);

  const currentGroup = groups.find((group) => group.id === chosenGroup);

  const handleChooseStory = (story: Story) => {
    if (chosenStories.includes(story.layerData.layersGroupId)) {
      setChosenStories(chosenStories.filter((id) => id !== story.layerData.layersGroupId));
    } else {
      setChosenStories([...chosenStories, story.layerData.layersGroupId]);
    }
  };

  useEffect(() => {
    setIsAddBtnDisabled(!chosenStories.length || !chosenGroup);
  }, [chosenStories.length, chosenGroup]);

  const handleAfterCreating = () => {
    history.push(
      `/editor/${props.appId}/${category === Templates.Category.STORIES ? 'group' : 'onboarding'
      }/${chosenGroup}`
    );
  };

  const createFromTemplate = useCreateStoriesFromTemplate({
    currentAppId: appId,
    currentGroup,
    onBeforeCreating: () => setIsAddBtnDisabled(true),
    onAfterCreating: handleAfterCreating,
    templateSettings
  });

  const handleChooseGroup = (groupId: string) => {
    if (chosenGroup === groupId) {
      setChosenGroup('');
    } else {
      setChosenGroup(groupId);
    }
  };

  const handleAddModalOpen = (type: GroupsType) => {
    if (type === GroupsType.GROUP) {
      onOpenAddModal && onOpenAddModal(Templates.Category.STORIES);
    } else if (type === GroupsType.ONBOARDING) {
      onOpenAddModal && onOpenAddModal(Templates.Category.ONBOARDING);
    }
  };

  const createFromTemplates = () => {
    const selectedStories = props.stories.filter((story) => chosenStories.includes(story.id));

    createFromTemplate(selectedStories);
  };

  return (
    <FreshModal
      isOpen={isOpen}
      isProhibitedOutsideClick={isProhibitedOutsideClick}
      size="md"
      onClose={onClose}
    >
      <div className={b()}>
        <FreshModalTitle>
          {category === Templates.Category.STORIES
            ? t('dashboard.modals.addTemplate.chooseGroup')
            : t('dashboard.modals.addTemplate.chooseOnboarding')}
        </FreshModalTitle>

        <div className={b('fieldSet')}>
          <p className={b('fieldSetTitle')}>
            {t('dashboard.modals.addTemplate.chooseTemplatesStep')}
          </p>
          <div className={b('container')}>
            {activeStories.map((story) => (
              <StoryChoosePreview
                cardHeight={170}
                cardWidth={96}
                handleChoose={handleChooseStory}
                isChoosen={chosenStories.includes(story.layerData.layersGroupId)}
                key={`choosen-story-preview-${story.id}`}
                story={story}
              />
            ))}
          </div>
        </div>

        <div className={b('fieldSet')}>
          <p className={b('fieldSetTitle')}>{t('dashboard.modals.addTemplate.chooseGroupStep')}</p>

          <GroupChooseItems
            chosenGroupId={chosenGroup}
            groups={groups}
            type={
              category === Templates.Category.ONBOARDING ? GroupsType.ONBOARDING : GroupsType.GROUP
            }
            onAddModalOpen={handleAddModalOpen}
            onChoose={handleChooseGroup}
          />
        </div>

        <FreshButton
          disabled={isAddBtnDisabled}
          text={`${t('dashboard.modals.addTemplate.addTemplates')} (${chosenStories.length})`}
          onClick={createFromTemplates}
        />
      </div>
    </FreshModal>
  );
};
