import React, { useEffect, useState } from 'react';
import { CreateGroupContainer, CreateOnboardingContainer, CropProps } from '@features';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { GroupType } from '@storysdk/react';
import { IconStartOnboarding, IconStartStories } from '@components';
import { FreshModalTitle } from '@components/_fresh';
import './AddGroupPage.scss';

const b = block('AddGroupPage');

interface AddGroupPageProps {
  currentAppId: string;
  onGroupCreated: (groupId: string) => void;
}

export const AddGroupPage: React.FC<AddGroupPageProps> = ({ currentAppId, onGroupCreated }) => {
  const { t } = useTranslation();

  const initialCroppedState = {
    dataUrl: null,
    blob: null
  };

  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);
  const [activeTab, setActiveTab] = useState<GroupType>(GroupType.GROUP);
  const [creationType, setCreationType] = useState<GroupType | undefined>();

  useEffect(() => {
    if (creationType) {
      setActiveTab(creationType);
    }
  }, [creationType]);

  if (!creationType) {
    return (
      <div className={b()}>
        <div className={b('creationTabs')}>
          <FreshModalTitle>
            {t('dashboard.modals.addNewGroup.whatDoYouWantToCreate')}
          </FreshModalTitle>
          <div className={b('creationTabsContainer')}>
            <button
              className={b('creationTabItem')}
              onClick={() => setCreationType(GroupType.GROUP)}
            >
              <div className={b('creationTabItemTitle')}>
                <IconStartStories
                  className={b('icon', {
                    stroked: true,
                    active: true,
                    stories: true
                  }).toString()}
                />
                {t('dashboard.sideMenu.stories')}
              </div>
              <p className={b('creationTabItemDescription')}>
                {t('dashboard.modals.addNewGroup.storiesDescription')}
              </p>
            </button>

            <button
              className={b('creationTabItem')}
              onClick={() => setCreationType(GroupType.ONBOARDING)}
            >
              <div className={b('creationTabItemTitle')}>
                <IconStartOnboarding
                  className={b('icon', {
                    filled: true,
                    active: true
                  }).toString()}
                />
                {t('dashboard.sideMenu.onboarding')}
              </div>
              <p className={b('creationTabItemDescription')}>
                {t('dashboard.modals.addNewGroup.onboardingDescription')}
              </p>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <button
          className={b('tab', { active: activeTab === GroupType.GROUP })}
          type="button"
          onClick={() => setActiveTab(GroupType.GROUP)}
        >
          <IconStartStories
            className={b('icon', {
              stroked: true,
              active: activeTab === GroupType.GROUP,
              stories: true
            }).toString()}
          />
          {t('dashboard.sideMenu.stories')}
        </button>
        <button
          className={b('tab', { active: activeTab === GroupType.ONBOARDING })}
          type="button"
          onClick={() => setActiveTab(GroupType.ONBOARDING)}
        >
          <IconStartOnboarding
            className={b('icon', {
              filled: true,
              active: activeTab === GroupType.ONBOARDING
            }).toString()}
          />
          {t('dashboard.sideMenu.onboarding')}
        </button>
      </div>
      {activeTab === GroupType.GROUP ? (
        <CreateGroupContainer
          className={b('container')}
          clearCurrentImage={() => setCroppedImage(croppedImage)}
          currentAppId={currentAppId}
          currentImage={croppedImage}
          defaultName={t('dashboard.start.firstGroup')}
          onClose={onGroupCreated}
          onImageLoaded={setCroppedImage}
        />
      ) : (
        <CreateOnboardingContainer
          className={b('container').toString()}
          clearCurrentImage={() => setCroppedImage(croppedImage)}
          currentAppId={currentAppId}
          currentImage={croppedImage}
          defaultName={t('dashboard.start.firstOnboarding')}
          onClose={onGroupCreated}
        />
      )}
    </div>
  );
};
