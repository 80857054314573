import React, { FC } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { AppPlan } from '@features/user/types';
import { IconCheckArrow } from '@components';
import { FreshButton } from '@components/_fresh';
import './PlansCard.scss';

const b = block('PlansCard');

const MONTHS_IN_YEAR = 12;
const CENTS_IN_DOLLAR = 100;

type PlanPrice = {
  id: string;
  amount: number;
  currency: string;
};

interface PlansCardProps {
  name?: AppPlan;
  description?: string;
  isLoading?: boolean;
  isActive?: boolean;
  isUpgradable?: boolean;
  isDowngradable?: boolean;
  isUpgrading?: boolean;
  isTrialing?: boolean;
  showMode?: 'monthly' | 'yearly';
  isDisabledForSoon?: boolean;
  prices?: {
    monthly: PlanPrice;
    yearly: PlanPrice;
  };
  features?: string[];
  onBuyClick?: (priceId: string, plan?: AppPlan) => void;
  onCancelClick?: () => void;
  onUpgradeClick?: () => void;
  onDowngradeClick?: () => void;
}

export const PlansCard: FC<PlansCardProps> = ({
  name,
  description,
  showMode = 'yearly',
  prices,
  isDisabledForSoon,
  isActive,
  isTrialing,
  isUpgradable,
  isDowngradable,
  isLoading,
  features,
  isUpgrading,
  onBuyClick,
  onCancelClick,
  onUpgradeClick,
  onDowngradeClick
}) => {
  const { t } = useTranslation();

  const getButtonParams = () => {
    if (isDisabledForSoon) {
      return { title: t('plans.choose') };
    }

    if (isUpgrading) {
      if (isDowngradable && !isUpgradable) {
        return { title: t('plans.downgrading') };
      }

      return { title: t('plans.upgrading') };
    }

    if (isTrialing) {
      return { title: t('plans.choose'), action: onBuyClick };
    }

    if (isActive) {
      return { title: t('plans.cancel'), action: onCancelClick };
    }

    if (isUpgradable) {
      return { title: t('plans.upgrade'), action: onUpgradeClick };
    }

    if (isDowngradable) {
      return { title: t('plans.downgrade'), action: onDowngradeClick };
    }

    return { title: t('plans.choose'), action: onBuyClick };
  };

  const getPrice = () => {
    if (!prices) {
      return 0;
    }

    if (showMode === 'yearly') {
      return Math.round(prices[showMode].amount / CENTS_IN_DOLLAR / MONTHS_IN_YEAR);
    }

    return Math.round(prices[showMode].amount / CENTS_IN_DOLLAR);
  };

  const getPrices = () => {
    if (!prices) {
      return {
        monthly: 0,
        yearly: 0
      };
    }

    return {
      monthly: Math.round(prices.monthly.amount / CENTS_IN_DOLLAR),
      yearly: Math.round(prices.yearly.amount / CENTS_IN_DOLLAR / MONTHS_IN_YEAR)
    };
  };

  const getCurrency = () => {
    if (!prices) {
      return '$';
    }

    return prices[showMode].currency === 'usd' ? '$' : prices[showMode].currency;
  };

  const getDesc = () => {
    if (name === AppPlan.FREE) {
      return t('plans.oneProject');
    }

    if (name === AppPlan.INDIE) {
      return t('plans.forOneProject');
    }

    return t('plans.unlimitedProjects');
  };

  return (
    <div
      className={b({
        disabled: isDisabledForSoon
      })}
    >
      <div className={b('titleContainer')}>
        {isLoading ? (
          <Skeleton
            height={22}
            style={{
              borderRadius: 12
            }}
            width="100%"
          />
        ) : (
          <>
            <div className={b('title')}>{name}</div>
            {isActive && <span className={b('label')}>{t('plans.active')}</span>}
            {isDisabledForSoon && (
              <span className={b('label', { right: true })}>{t('plans.soon')}</span>
            )}
          </>
        )}
      </div>
      <div className={b('desc')}>
        {isLoading ? (
          <Skeleton
            height={32}
            style={{
              borderRadius: 12
            }}
            width="100%"
          />
        ) : (
          description
        )}
      </div>
      <div className={b('prices')}>
        <div className={b('price')}>
          {isLoading ? (
            <Skeleton
              height={45}
              style={{
                borderRadius: 12
              }}
              width="100%"
            />
          ) : (
            <>
              <div className={b('priceValue')}>
                {showMode === 'yearly' && getPrices().monthly !== 0 && (
                  <>
                    <span className={b('priceValue', { crossed: true })}>
                      {getPrices().monthly}
                      {getCurrency()}
                    </span>{' '}
                  </>
                )}
                {getPrice()}
                {getCurrency()} / {t('plans.month')}
              </div>

              <p className={b('priceDesc')}>
                {getDesc()}{' '}
                {showMode === 'yearly' && getPrice() !== 0 && (
                  <>
                    ({getPrices().yearly * 12}
                    {getCurrency()}/{t('plans.year')})
                  </>
                )}
              </p>
            </>
          )}
        </div>
      </div>
      <div className={b('buttonContainer')}>
        {isLoading ? (
          <Skeleton
            height={71}
            style={{
              borderRadius: 12
            }}
            width="100%"
          />
        ) : (
          <>
            <FreshButton
              className={b('button').toString()}
              color={
                (isActive && !isTrialing) ||
                  (isDowngradable && !isActive && !isUpgradable && !isTrialing)
                  ? 'grey-light'
                  : 'pink'
              }
              disabled={isUpgrading}
              size="md"
              text={getButtonParams()?.title}
              type="submit"
              onClick={() => {
                if (prices) {
                  getButtonParams()?.action?.(prices[showMode].id, name);
                }
              }}
            />
            <p className={b('buttonDesc')}>
              {isActive ? t('plans.renewAnytime') : t('plans.cancelAnytime')}
            </p>
          </>
        )}
      </div>

      <div className={b('features')}>
        {!isLoading && <p className={b('featuresTitle')}>{t('plans.keyFeatures')}</p>}

        <div className={b('featuresList')}>
          {isLoading ? (
            <>
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton
                  height={24}
                  key={`feature-${index}`}
                  style={{
                    borderRadius: 12
                  }}
                  width="100%"
                />
              ))}
            </>
          ) : (
            <>
              {features?.map((feature, index) => (
                <div className={b('feature')} key={feature}>
                  <IconCheckArrow className={b('featureIcon').toString()} />
                  <p className={b('featureTitle', { bold: index < 4 && name !== AppPlan.FREE })}>
                    {feature}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
