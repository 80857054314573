import React, { useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';
import {
  AddTemplatesModal,
  CreateGroupModal,
  CreateOnboardingModal,
  CropProps,
  StoriesTemplatesItemContainer,
  Story,
  Templates,
  templateStoriesSlice,
  useFetchTemplates,
  useRedirectIfNotConfirmed
} from '@features';
import { StoriesTemplatesCatalogContainer } from '@features/storyTemplates/containers/StoriesTemplatesCatalogContainer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useIsAdmin } from '@hooks';
import './StoryTemplatesRoute.scss';

const b = block('StoryTemplatesRoute');

export const StoryTemplatesRoute: React.FC = () => {
  const initialCroppedState = {
    dataUrl: null,
    blob: null
  };

  const { category, appId, templateId } = useParams<{
    category: Templates.Category;
    appId?: string;
    templateId?: string;
  }>();

  const history = useHistory();
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();
  const [createGroupModal, setCreateGroupModal] = useState<Templates.Category | null>(null);
  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState<boolean>(false);

  useRedirectIfNotConfirmed();

  useEffect(() => {
    if (!category && appId) {
      history.push(`/dashboard/${appId}/templates/stories`);
    }
  }, [history, category, appId]);

  const templateStories = useSelector((store: RootState) => store.templateStories);
  const selectedStoryLayers = templateStories.selectedStoryLayers;
  const allStoriesArr = Object.values(templateStories.stories);
  const templates = useSelector((store: RootState) => store.templates);
  const currentTemplate = templates.templates.find((template) => template.id === templateId);

  const currentApp = useSelector((state: RootState) => state.appManager.current);
  const groups = useSelector((state: RootState) => state.groups.groups);
  const onboardings = useSelector((state: RootState) => state.onboardings.onboardings);
  const currentAppId = currentApp?.id;

  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);

  // const selectedStories = useMemo(() => {
  //   const activeStoriesArr = getActiveStoriesFlattenedArr(templateStories.stories);

  //   return activeStoriesArr.filter((story) =>
  //     selectedStoryLayers.find((layer) => layer.id === story.layerData.layersGroupId)
  //   );
  // }, [selectedStoryLayers, templateStories.stories]);

  // const createFromTemplate = useCreateStoriesFromTemplate({
  //   currentAppId,
  //   currentGroup,
  //   templateSettings: currentTemplate?.settings,
  //   onBeforeCreating: () => setIsContinueDisabled(true),
  //   onAfterCreating: isMobile ? handleGoToDashboard : handleGoToEditor
  // });

  const chooseStory = useCallback(
    (story: Story) => {
      if (selectedStoryLayers.find((layer) => layer.id === story.layerData.layersGroupId)) {
        dispatch(templateStoriesSlice.actions.removeSelectedStory(story.layerData.layersGroupId));
      } else {
        const selectedLayer = templateStories.stories[story.layerData.layersGroupId];

        dispatch(templateStoriesSlice.actions.addSelectedStory({ layer: selectedLayer, category }));
      }
    },
    [category, dispatch, selectedStoryLayers, templateStories.stories]
  );

  const chooseAllStories = useCallback(() => {
    if (selectedStoryLayers.length) {
      dispatch(templateStoriesSlice.actions.setSelectedStories({ layers: [], category }));
    } else if (allStoriesArr.length) {
      dispatch(
        templateStoriesSlice.actions.setSelectedStories({ layers: allStoriesArr, category })
      );
    }
  }, [allStoriesArr, category, dispatch, selectedStoryLayers.length]);

  const handleChooseAllStories = useCallback(
    (isAllChosen: boolean) => {
      if (currentTemplate?.settings?.scoreResultLayersGroupId && isAllChosen) {
        dispatch(templateStoriesSlice.actions.setSelectedGroupSettings(currentTemplate?.settings));
      } else {
        dispatch(templateStoriesSlice.actions.setSelectedGroupSettings(null));
      }
    },
    [currentTemplate?.settings, dispatch]
  );

  useFetchTemplates({
    category,
    isWithStories: true,
    isOnlyActive: !isAdmin
  });

  return (
    <div className={b()}>
      {!templateId ? (
        <StoriesTemplatesCatalogContainer
          category={category}
          onOpenAddModal={setIsAddTemplateOpen}
        />
      ) : (
        <StoriesTemplatesItemContainer
          category={category}
          chosenStories={selectedStoryLayers}
          templateId={templateId}
          onAfterChooseAllStories={handleChooseAllStories}
          onChooseAllStories={chooseAllStories}
          onChooseStory={chooseStory}
          onOpenAddModal={setIsAddTemplateOpen}
        />
      )}

      <AddTemplatesModal
        appId={currentAppId ?? ''}
        category={category}
        groups={category === Templates.Category.STORIES ? groups : onboardings}
        isOpen={isAddTemplateOpen}
        isProhibitedOutsideClick={createGroupModal !== null}
        stories={selectedStoryLayers}
        onClose={() => setIsAddTemplateOpen(false)}
        onOpenAddModal={(addCategory: Templates.Category) => setCreateGroupModal(addCategory)}
      />

      {category === Templates.Category.STORIES && (
        <CreateGroupModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentAppId={currentAppId ?? undefined}
          currentImage={croppedImage}
          isOpen={createGroupModal === Templates.Category.STORIES}
          onClose={() => setCreateGroupModal(null)}
          onImageLoaded={setCroppedImage}
        />
      )}
      {category === Templates.Category.ONBOARDING && (
        <CreateOnboardingModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentAppId={currentAppId ?? undefined}
          currentImage={croppedImage}
          isOpen={createGroupModal === Templates.Category.ONBOARDING}
          onClose={() => setCreateGroupModal(null)}
        />
      )}
    </div>
  );
};
