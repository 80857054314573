import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useInterval } from '@hooks';
import { Icon } from '@components';
import { FreshButton } from '@components/_fresh';

import './AuthPageConfirmMessage.scss';

const MILLIS_IN_MINUTE = 60000;
const MILLIS_IN_SECOND = 1000;

const b = block('AuthPageMessage');
const AUTH_PAGE_MESSAGE_CLASSNAME = 'AuthPageMessage';

interface Props {
  email: string;
  onButtonClick: () => void;
  isSending?: boolean;
}

export const AuthPageConfirmMessage: React.FunctionComponent<Props> = (props) => {
  const { onButtonClick, email, isSending } = props;

  const [leftMillis, setLeftMillis] = useState<number>(MILLIS_IN_MINUTE);

  const resendConfirmation = () => {
    setLeftMillis(MILLIS_IN_MINUTE);
    onButtonClick();
  };

  useInterval(
    () => {
      setLeftMillis(leftMillis - MILLIS_IN_SECOND);
    },
    leftMillis ? MILLIS_IN_SECOND : null
  );

  useEffect(() => {
    if (isSending) {
      setLeftMillis(MILLIS_IN_SECOND);
    }
  }, [isSending]);

  const { t } = useTranslation();

  return (
    <section className={b()}>
      <Icon className={cn(`${AUTH_PAGE_MESSAGE_CLASSNAME}__icon`)} name="circle-success-fresh" />
      <p className={cn(`${AUTH_PAGE_MESSAGE_CLASSNAME}__title`)}>
        {t('auth.confirmEmail')}{' '}
        <span className={`${AUTH_PAGE_MESSAGE_CLASSNAME}__title_bold`}>{email}</span>{' '}
        {t('auth.startUsing')}
      </p>
      <FreshButton
        className={`${AUTH_PAGE_MESSAGE_CLASSNAME}__btn`}
        disabled={leftMillis > 0 || isSending}
        text={t('auth.resendConfirmationBtn')}
        type="submit"
        onClick={resendConfirmation}
      />

      <p className={cn(`${AUTH_PAGE_MESSAGE_CLASSNAME}__timer-text`)}>
        {t('auth.resendConfirmation')}{' '}
        {leftMillis > 0 && (
          <>
            {' '}
            {t('auth.via')} {DateTime.fromMillis(leftMillis).toFormat('mm:ss')}{' '}
          </>
        )}
      </p>
    </section>
  );
};
