import React from 'react';
import { FormSubmitHandler } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { NewPasswordForm } from '@forms';
import { MessageTypes, informSlice } from '@features';
import { useTranslation } from 'react-i18next';
import { Auth } from '@utils';
import { AuthLayout } from '@layouts';
import { useAppDispatch } from '../../../store';
import { FooterLinks } from '../_components';

export const NewPasswordPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleSubmit: FormSubmitHandler = (data) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/new-password`, {
      method: 'post',
      body: JSON.stringify(data)
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          Auth.updateStorage(response);
          history.push('/dashboard');
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrongs')
            })
          );
        }
      });
  };

  return (
    <AuthLayout
      footer={
        <FooterLinks
          links={[
            {
              title: t('auth.signIn'),
              href: '/'
            },
            {
              title: t('auth.signUp'),
              href: '/signup'
            },
            {
              title: t('auth.help'),
              href: 'https://docs.storysdk.com/',
              isExternal: true
            }
          ]}
        />
      }
      template="form"
      title={t('auth.newPassword')}
    >
      <NewPasswordForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};
