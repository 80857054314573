import React from 'react';
import block from 'bem-cn';
import { AnalyticsHeader, AnalyticsStoryGroupsTab } from '@modules/DashboardModule/components';
import './AppAnalyticsRoute.scss';
import { useRedirectIfNotConfirmed } from '@features';

const b = block('AppAnalyticsRoute');

export const AppAnalyticsRoute: React.FC = () => {
  useRedirectIfNotConfirmed();

  return (
    <div className={b()}>
      <AnalyticsHeader />
      <AnalyticsStoryGroupsTab />
    </div>
  );
};
