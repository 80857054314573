import { MessageTypes, informSlice } from '@features/inform';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store';
import { useCallback } from 'react';
import { useGetUserSubscription } from '@hooks';
import { AccesibleFeaturesByPlan, AppFeatures, AppLimits, LimitsByPlan } from '../consts';
import { AppPlan } from '../types';

export const useGetActionByAccess = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userSubcription = useGetUserSubscription();
  const isAppOwner = userSubcription.isAppOwner;

  let plan = userSubcription.plan;

  if (plan === 'Startup' || plan === 'Business') {
    plan = AppPlan.STARTUP;
  } else if (plan === 'Indie') {
    plan = AppPlan.INDIE;
  }

  return useCallback(
    (feature: AppFeatures, action: (params?: any) => void) => {
      let isAccessible = false;

      if (
        userSubcription.isConfirmed &&
        userSubcription.isSubscribed &&
        AccesibleFeaturesByPlan[plan as AppPlan][feature] &&
        (feature === AppFeatures.CREATE_STORIES ||
          feature === AppFeatures.CREATE_ONBOARDING ||
          feature === AppFeatures.PUBLISH_STORIES) &&
        (LimitsByPlan[plan as AppPlan][AppLimits.STORIES_COUNT] === 0 ||
          LimitsByPlan[plan as AppPlan][AppLimits.STORIES_COUNT] > userSubcription.storiesCount)
      ) {
        isAccessible = true;
      } else if (
        userSubcription.isSubscribed &&
        AccesibleFeaturesByPlan[plan as AppPlan][feature] &&
        feature === AppFeatures.PUBLISH_STORIES &&
        (LimitsByPlan[plan as AppPlan][AppLimits.STORIES_COUNT] === 0 ||
          LimitsByPlan[plan as AppPlan][AppLimits.STORIES_COUNT] >
          userSubcription.publishedStoriesCount)
      ) {
        isAccessible = true;
      } else if (
        userSubcription.isConfirmed &&
        userSubcription.isSubscribed &&
        AccesibleFeaturesByPlan[plan as AppPlan][feature] &&
        feature === AppFeatures.COLLABORATORS &&
        (LimitsByPlan[plan as AppPlan][AppLimits.COLLABORATORS_COUNT] === 0 ||
          LimitsByPlan[plan as AppPlan][AppLimits.COLLABORATORS_COUNT] >
          userSubcription.collaboratorsCount)
      ) {
        isAccessible = true;
      } else if (
        userSubcription.isConfirmed &&
        userSubcription.isSubscribed &&
        feature !== AppFeatures.CREATE_STORIES &&
        feature !== AppFeatures.PUBLISH_STORIES &&
        feature !== AppFeatures.CREATE_ONBOARDING &&
        feature !== AppFeatures.COLLABORATORS &&
        AccesibleFeaturesByPlan[plan as AppPlan][feature]
      ) {
        isAccessible = true;
      }

      if (isAccessible) {
        action();
      } else if (
        userSubcription.isTrialing &&
        userSubcription.trialLeft <= 0 &&
        userSubcription.isConfirmed
      ) {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.NEED_ACTION,
            text: t('notification.subscription.needAction'),
            action: isAppOwner
              ? {
                text: t('plans.upgrade'),
                link: '/account/plans',
                icon: 'upgrade-arrow'
              }
              : undefined
          })
        );
      } else if (!userSubcription.isConfirmed) {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.CONFIRM_EMAIL,
            text: t('notification.user.confirmEmail'),
            action: {
              text: t('auth.resendConfirmationBtn'),
              link: '/resendConfirmation',
              value: userSubcription.userEmail
            }
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.DONT_HAVE_ACCESS,
            text: isAppOwner
              ? t('notification.subscription.dontHaveAccess')
              : t('notification.subscription.dontHaveAccessCollaborator'),
            action: isAppOwner
              ? {
                text: t('plans.upgrade'),
                link: '/account/plans',
                icon: 'upgrade-arrow'
              }
              : undefined
          })
        );
      }
    },
    [userSubcription, dispatch, t]
  );
};
