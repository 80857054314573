import React from 'react';
import { Field, reduxForm } from 'redux-form';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store';
import { informSlice, MessageTypes } from '@features';
import { validators, renderLightField } from '@utils';
import { IconGoogle, IconMicrosoft } from '@components';
import { FreshButton } from '@components/_fresh';
import './SignInForm.scss';

const b = block('SignInForm');

export const SignInForm = reduxForm({
  form: 'signIn'
})((props) => {
  const { handleSubmit, submitting, invalid, pristine } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const gLogin = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/google_login`, {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((data) => {
        if (data.data && !data.error) {
          window.open(data.data, '_self');
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: JSON.stringify(data.error)
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: JSON.stringify(error)
          })
        );
      });
  };

  const microsoftLogin = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/microsoft_login`, {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((data) => {
        if (data.data && !data.error) {
          window.open(data.data, '_self');
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: JSON.stringify(data.error)
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: JSON.stringify(error)
          })
        );
      });
  };

  return (
    <form className={b()} onSubmit={handleSubmit}>
      <div className={b('socialContainer')}>
        <FreshButton
          className={b('btn')}
          color="transparent-grey-light"
          leftIcon={<IconGoogle className={b('btnIcon').toString()} />}
          text={t('auth.signInWithGoogle')}
          onClick={gLogin}
        />
        <FreshButton
          className={b('btn')}
          color="transparent-grey-light"
          leftIcon={<IconMicrosoft className={b('btnIcon').toString()} />}
          text={t('auth.signInWithMicrosoft')}
          onClick={microsoftLogin}
        />
      </div>

      <Field
        component={renderLightField}
        label={t('auth.email')}
        name="email"
        placeholder={t('auth.email')}
        type="email"
        validate={[validators.required, validators.email]}
      />
      <Field
        component={renderLightField}
        label={t('auth.password')}
        name="password"
        placeholder={t('auth.password')}
        type="password"
        validate={[validators.required]}
      />
      <FreshButton
        className={b('btn').toString()}
        disabled={invalid || submitting || pristine}
        text={t('auth.signIn')}
        type="submit"
      />
    </form>
  );
});
