import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import { API } from '@services';
import { informSlice, MessageTypes, userSlice } from '@features';
import { Auth } from '@utils';
import { FreshOutput, FreshButton, FreshInput } from '@components/_fresh';
import { IconSaveFresh } from '@components';
import { ChangePasswordModal } from '../ChangePasswordModal/ChangePasswordModal';
import './AccountSettingsTab.scss';

interface FormProps {
  name: string | null;
  email: string | null;
}

const b = block('AccountSettingsTab');
const s = block('GroupSettingsTab');

export const AccountSettingsTab: React.FunctionComponent = () => {
  const initialFormData = {
    name: null,
    email: null
  };

  const appState = useSelector((state: RootState) => state);
  const user = appState.user;
  const dispatch = useAppDispatch();

  const [userLocale, setUserLocale] = useState(user.user.locale);

  const { t } = useTranslation();

  useEffect(() => {
    setUserLocale(user.user.locale);
  }, [user.user.locale]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    changeLanguage(userLocale);

    if (formData.name && user.user.name !== formData.name) {
      const { data } = await API.user.changeName(formData.name);
      {
        if (data && !data.error) {
          Auth.updateUser({ full_name: formData.name });
          dispatch(userSlice.actions.setName(formData.name));

          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.SUCCESS,
              text: t('notification.user.updated')
            })
          );
        }
      }
    }
  };

  const handleChangePasswordClick = () => {
    if (!user.user.email) {
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'post',
      body: JSON.stringify({ email: user.user.email })
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          setPasswordOpen(true);
        }
      });
  };

  const [isPasswordOpen, setPasswordOpen] = useState<boolean>(false);

  const [formData, setFormData] = useState<FormProps>(initialFormData);

  useEffect(() => {
    if (Auth.isLoggedIn) {
      setFormData({
        name: user.user.name,
        email: user.user.email
      });
    }
  }, [user.user]);

  const handleSubmitNewPasswordForm = (data: any) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/new-password`, {
      method: 'post',
      body: JSON.stringify(data)
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.SUCCESS,
              text: t('notification.user.passwordUpdated')
            })
          );

          Auth.updateStorage(response);
          setPasswordOpen(false);
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrong')
            })
          );
        }
      });
  };

  const handleChangeName = (value: string) => {
    setFormData({ ...formData, name: value });
  };

  return (
    <>
      <form className={b()} onSubmit={handleSubmit}>
        <div className={s('formHeader')}>
          <h1 className={s('formHeaderTitle')}>{t('user.accountSettings')}</h1>
          <FreshButton
            disabled={!formData.name}
            leftIcon={<IconSaveFresh />}
            size="md"
            text={t('form.saveSettings')}
            type="submit"
          />
        </div>

        <div className={s('formColumn')}>
          <div className={s('filedSet', { noBorder: true })}>
            <div className={s('fieldGroup')}>
              <p className={s('fieldLabel')}>{t('form.email')}</p>
              <FreshOutput className={`${b('descGroup')}`} value={formData.email as string} />
            </div>
          </div>

          <div className={s('filedSet', { noBorder: true })}>
            <div className={s('fieldGroup')}>
              <p className={s('fieldLabel')}>{t('form.name')}</p>
              <FreshInput
                fieldName="name"
                invalid={!formData.name}
                placeholder="Name"
                type="text"
                value={formData.name as string}
                onChange={(params) => handleChangeName(params.value)}
              />
            </div>
          </div>
        </div>

        <a
          className={s('link')}
          role="button"
          tabIndex={0}
          onClick={handleChangePasswordClick}
          onKeyDown={handleChangePasswordClick}
        >
          {t('user.changePassword')}
        </a>
      </form>

      <ChangePasswordModal
        handleSubmitNewPasswordForm={handleSubmitNewPasswordForm}
        isOpen={isPasswordOpen}
        onClose={() => setPasswordOpen(false)}
      />
    </>
  );
};
