import React, { useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Story,
  StoryChoosePreview,
  StoryLayer,
  StoryLayers,
  Templates,
  useFetchTemplateStories
} from '@features';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import Skeleton from 'react-loading-skeleton';
import { HeaderButton, IconCart, IconEditFresh } from '@components';
import { FreshButton } from '@components/_fresh';
import { getActiveStoriesFlattenedArr } from '@utils';
import { useIsAdmin } from '@hooks';
import './StoriesTemplatesItemContainer.scss';

const b = block('StoriesTemplatesItemContainer');

interface StoriesTemplatesItemContainerProps {
  templateId: string;
  category?: Templates.Category;
  chosenStories: StoryLayer[];
  stories?: StoryLayers;
  onOpenAddModal?: (isOpen: boolean) => void;
  onChooseStory: (story: Story) => void;
  onChooseAllStories: () => void;
  onBack?: () => void;
  onAfterChooseAllStories?(isAllChosen: boolean): void;
}

const MOCK_STORY_STYLE = {
  width: 174,
  height: 309,
  borderRadius: 8
};

export const StoriesTemplatesItemContainer: React.FC<StoriesTemplatesItemContainerProps> = ({
  templateId,
  chosenStories,
  category,
  stories,
  onOpenAddModal,
  onBack,
  onChooseStory,
  onChooseAllStories,
  onAfterChooseAllStories
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const templates = useSelector((state: RootState) => state.templates);
  const templateStories = useSelector((state: RootState) => state.templateStories);
  const currentAppId = useSelector((state: RootState) => state.appManager.current?.id);
  const appStatus = useSelector((state: RootState) => state.appManager.status);
  const templateStatus = useSelector((state: RootState) => state.templates.status);
  const activeChosenStories = chosenStories.map((story) => story.layers[story.activeLayerId]);

  const currentTemplate = useMemo(
    () => templates.templates.find((template) => template.id === templateId),
    [templateId, templates]
  );

  useFetchTemplateStories({
    templateId,
    stories
  });

  const isAdmin = useIsAdmin();

  const handleGoBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }

    if (currentAppId) {
      history.push(`/dashboard/${currentAppId}/templates/${category}`);
    }
  }, [category, history, currentAppId, onBack]);

  const isLoading = useMemo(
    () =>
      templateStories.status === 'pending' ||
      appStatus === 'pending' ||
      templateStatus === 'pending',
    [appStatus, templateStatus, templateStories.status]
  );

  useEffect(() => {
    if (!currentTemplate && templates.status === 'loaded') {
      handleGoBack();
    }
  }, [currentTemplate, handleGoBack, templates.status]);

  const currentStories = getActiveStoriesFlattenedArr(templateStories.stories);

  const openEditor = useCallback(() => {
    history.push(`/templateEditor/template/${currentTemplate?.category}/${templateId}`);
  }, [history, templateId, currentTemplate]);

  useEffect(() => {
    if (activeChosenStories.length === 0) {
      return;
    }

    const isAllChoosen = activeChosenStories.every((story) =>
      currentStories.find((currentStory) => currentStory.id === story.id)
    );

    if (onAfterChooseAllStories) {
      onAfterChooseAllStories(isAllChoosen);
    }
  }, [activeChosenStories, currentStories, onAfterChooseAllStories]);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('headerContainer')}>
          <HeaderButton
            color="transparent-black"
            icon="back-fresh"
            iconVariant="editor"
            onClick={handleGoBack}
          >
            {t('editor.header.back')}
          </HeaderButton>

          <div className={b('titleContainer')}>
            <h1 className={b('title')}>{currentTemplate?.title}</h1>
            <p className={b('desc')}>{currentTemplate?.description}</p>
          </div>

          <div className={b('btnsContainer')}>
            {isAdmin && (
              <FreshButton
                className={b('btn')}
                color="transparent-grey"
                leftIcon={<IconEditFresh className={b('btnIcon').toString()} />}
                size="sm"
                text={t('dashboard.stories.openEditor')}
                onClick={openEditor}
              />
            )}

            {currentStories.length > 0 && (
              <FreshButton
                className={b('btn')}
                color="dark-grey"
                size="sm"
                text={t('dashboard.templates.chooseAll')}
                onClick={onChooseAllStories}
              />
            )}

            {onOpenAddModal && (
              <FreshButton
                className={b('btn').toString()}
                disabled={!activeChosenStories.length}
                leftIcon={<IconCart className={b('btnIcon').toString()} />}
                size="md"
                text={`${t('dashboard.templates.continue')} (${activeChosenStories.length})`}
                onClick={() => {
                  onOpenAddModal(true);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className={b('storiesContainer')}>
        {isLoading ? (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <div className={b('storyItem')} key={`storyMockPreview-${index}`}>
                <Skeleton
                  height={MOCK_STORY_STYLE.height}
                  style={{ borderRadius: MOCK_STORY_STYLE.borderRadius }}
                  width={MOCK_STORY_STYLE.width}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {currentStories.length > 0 && currentTemplate ? (
              currentStories.map((story) => (
                <StoryChoosePreview
                  handleChoose={onChooseStory}
                  isChoosen={
                    !!activeChosenStories.find((currentStory) => currentStory.id === story.id)
                  }
                  isFilled
                  isWithActions
                  key={`story-card-preview-${story.id}`}
                  stories={currentStories}
                  story={story}
                  template={currentTemplate}
                />
              ))
            ) : (
              <div className={b('empty')}>{t('dashboard.stories.emptyText')}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
