import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useRedirectIfNotConfirmed = () => {
  const user = useSelector((store: RootState) => store.user.user);

  useEffect(() => {
    if (!user.isEmailConfirmed && user.email) {
      window.location.href = '/dashboard';
    }
  }, [user.isEmailConfirmed, user.email]);
};
